<template>
  <div>
    <a-card title="仓储费用">
      <a-row gutter="16">
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.warehouse" placeholder="仓库" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-select v-model="searchForm.client" placeholder="客户" allowClear style="width: 100%" @change="search">
            <a-select-option v-for="item in clientItems" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="24" :md="6" :xl="5" style="max-width: 256px; margin-bottom: 12px">
          <a-input v-model="searchForm.search" placeholder="" allowClear @pressEnter="search" />
        </a-col>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <a-col :span="24" :md="4" :xl="4" style="margin-bottom: 12px">
          <a-button-group>
            <a-button icon="download" @click="exportExcel">导出</a-button>
          </a-button-group>
        </a-col>
      </a-row>
      <a-row gutter="16">
        <a-col :span="24">
          <a-table
            rowKey="id"
            size="small"
            :columns="columns"
            :data-source="items"
            :pagination="pagination"
            :scroll="{ y: 480 }"
            :loading="loading"
            @change="onChangeTable"
          >
            <template slot="footer" slot-scope="currentPageData">仓储总金额(元)：{{ totalAmount }}</template>
          </a-table>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>
<script>
import { exportExcel } from "@/utils/excel";
import { settlementStockRecordsExport } from "@/api/export";
import { chargeWarehouseStorageList, chargeWarehouseStorageTotal } from "@/api/charging";
import { clientOption, warehouseOption } from "@/api/option";

export default {
  name: "ChargeItemsPanel",
  data() {
    return {
      loading: false,
      searchForm: { page: 1, page_size: 48, has_amount: true },
      pagination: { current: 1, total: 0, pageSize: 48, showTotal: (total) => `共 ${total} 条` },
      totalAmount: 0,
      items: [],
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "仓库编号",
          dataIndex: "warehouse_number",
          key: "warehouse_number",
        },
        {
          title: "仓库名称",
          dataIndex: "warehouse_name",
          key: "warehouse_name",
        },
        {
          title: "客户编号",
          dataIndex: "client_number",
          key: "client_number",
        },
        {
          title: "客户名称",
          dataIndex: "client_name",
          key: "client_name",
        },
        {
          title: "计费数量",
          dataIndex: "measure_value",
          key: "measure_value",
        },
        {
          title: "计量单位",
          dataIndex: "measure_unit",
          key: "measure_unit",
        },
        {
          title: "计量单价",
          dataIndex: "measure_unit_price",
          key: "measure_unit_price",
        },
        {
          title: "结算金额(元)",
          dataIndex: "total_amount",
          key: "total_amount",
        },
        {
          title: "结算日期",
          dataIndex: "settlement_date",
          key: "settlement_date",
        },
      ],
      warehouseItems: [],
      clientItems: [],
    };
  },
  components: {},
  computed: {
    currentWarehouse() {
      return this.$store.state.user.currentWarehouse;
    },
  },
  methods: {
    initData() {
      warehouseOption({ page_size: 999999 }).then((data) => {
        this.warehouseItems = data.results;
        this.searchForm.warehouse = this.currentWarehouse;
        this.list();
      });
      clientOption({ page_size: 999999 }).then((data) => {
        this.clientItems = data.results;
      });
    },
    handelAdd() {
      this.$refs.modal_add.show();
    },
    search() {
      this.pagination.current = 1;
      this.searchForm.page = 1;
      this.list();
    },
    onChangeTable(pagination, filters, sorter) {
      this.pagination = pagination;
      this.searchForm.page = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    list() {
      this.loading = true;
      chargeWarehouseStorageList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.selectedRowKeys = [];
          this.loading = false;
        });
      chargeWarehouseStorageTotal()
        .then((data) => {
          this.totalAmount = data.total_amount;
        })
        .finally(() => {
          this.selectedRowKeys = [];
          this.loading = false;
        });
    },
    exportExcel() {
      settlementStockRecordsExport()
        .then((resp) => {
          exportExcel(resp, "仓储费用列表");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
